// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$zm-primary-color : #c7d326;
$zm-primary-var1-color : #c7d326;
$zm-primary-var2-color : #3D81B5;
$zm-lines-color: #6F6F6F;
$zm-accent-color: #55C9EB;
$zm-palette-map-default: (
  50: #e3f1fb,
  100: #bcdbf5,
  200: #93c5ef,
  300: #6daee7,
  400: #529ee3,
  500: $zm-primary-var1-color,
  600: #3881d1,
  700: #316fbe,
  800: #2b5fac,
  900: #21428c,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$zm-palette-map-accent: (
  50: #e1f6fb,
  100: #b4e7f5,
  200: #83d8f0,
  300: #55c8eb,
  400: #30bce9,
  500: $zm-primary-var2-color,
  600: #00a2db,
  700: #008fc8,
  800: #007eb5,
  900: #005f96,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trianz-primary: mat.define-palette($zm-palette-map-default);
$trianz-accent: mat.define-palette($zm-palette-map-accent, A200, A100, A400);
// The warn palette is optional (defaults to red).
$trianz-warn: mat.define-palette(mat.$red-palette);
$trianz-typography: mat.define-typography-config(
  $font-family: 'Open Sans, sans-serif'
);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trianz-theme: mat.define-light-theme((color: (primary: $trianz-primary,
        accent: $trianz-accent,
        warn: $trianz-warn,
      ),
      typography: $trianz-typography,
      density: -1,
      ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trianz-theme);
@include mat.form-field-density(-3);
// reduce the vertical height of checkboxes
.mat-mdc-checkbox{
  height: 30px;
}
// @media (-webkit-device-pixel-ratio: 1.25) {
//   * {
//     zoom: 0.99;
//   }
// }
// @media (-webkit-device-pixel-ratio: 1.5) {
//   * {
//     zoom: 0.985;
//   }
// }
// /* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Karla');
* {
  box-sizing: border-box;
  font-family: 'Karla';
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Karla' !important;
  font-display: swap;
}
.job-list-sec .container{
  width: auto !important;
}
label#sentenceForTnC {
  display: inline !important;
  color: #333;
  font-weight: 500;
  font-size: 22px;
}
.modal-header h4 {
  color: #333;
  font-weight: 600;
  margin: 0;
  font-size: 22px;
}
.read-policy-div p {
  font-size: 15px;
  margin-bottom: 10px;
}
.policy-content h3 {
  color: #333;
  font-weight: 500;
  font-size: 24px;
}
.read-policy-div ul {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
}
.read-policy-div a {
  color: #470b6b;
  font-weight: 600;
}
th, td {
  border: 1px solid #333 !important;
  padding: 5px 10px;
}
button.close {
  background: transparent;
  border: 0;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}
.mat-mdc-card-content .flex-nowrap .col{
  width: auto;
  display: inline;
}
.mat-mdc-card-content .flex-nowrap .col.fw-bold{
  font-weight: bold;
  margin-left: 5px;
}
.deparment-list .mat-mdc-card-content .row{
  margin-left: 0px;
  margin-right: 0px;
}
.mat-mdc-card-header-text{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mat-mdc-card-title a{
  font-size: 16px;
}
.mdc-card__actions{
  justify-content: left !important;
  min-height: unset !important;
  padding-top: 0 !important;
}
.search-bar .search-bar-input{
  width: 88% !important;
}
.search-bar .action-btns-group{
  width: 12% !important;
}
.search-bar .action-btns-group .mat-mdc-unelevated-button, .search-bar .action-btns-group .mat-mdc-outlined-button{
  height: 45px;
  top: -4px;
}
.section__xp__cta .exp-submit-mobile{
  margin-top: 15px;
}
.facet-lib .header{
  margin-bottom: 20px;
  align-items: end;
}
.mat-mdc-dialog-container .mdc-dialog__title{
  font-size: 16px !important;
}
.mdc-form-field>label{
  font-weight: normal !important;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  font-size: 14px !important;
  letter-spacing: 0px !important;
}
/*new css*/
.mat-mdc-card-header-text{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title {
  color: #333!important;
  font-weight: 600;
  font-size: 18px;
}
.accordion-button {
  color: #000 !important;
  font-size: 14px;
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  font-size: 14px;
  font-weight: 600;
}
/*new css*/
//loader
.zm-loader-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(248 249 250 / 0.4);
  justify-content: center;
  align-items: center;
  z-index: 998;
}
.zm-loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.zm-loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #c7d326;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
.zm-loader::before {
  width: 32px;
  height: 32px;
  border-color: #c7d326 #c7d326 transparent transparent;
  animation: rotation 1.5s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
//loader
//Bootstrap 5
.mt-2 {
  margin-top: 0 !important;
}
.mt-3 {
  margin-top: 0!important;
}
.section__xp__cta.d-flex.justify-content-center.mt-3 {
  justify-content: unset !important;
  display: block !important;
}
.mat-mdc-card-content .flex-nowrap .col {
  width: auto;
  display: inline;
}
.job-list-sec lib-job mat-card-content .col {
  flex: unset;
}
lib-job mat-card .mat-mdc-card-content .flex-nowrap .col {
  padding-left: 3px;
  padding-right: 0px;
}
table {
  width: 100% !important;
}
//Bootstrap 5
//Arrow Scroll Up
#return-to-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 99999;
  background: #ffff;
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 700;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#return-to-top img {
  margin: 0;
  position: relative;
  left: -3px;
  top: 7px;
  height: 13px;
  width: 45px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
//Arrow Scroll Up
//Facet-filter
.accordion-button:not(.collapsed){
  background-color: #fff !important;
}
h3.accordion-header{
  padding-bottom: 0px;
}
.accordion-body span.d-flex.mt-3.justify-content-end {
  justify-content: left !important;
  margin-top: 10px !important;
}
.filter-chips.d-flex {
  margin-bottom: 15px;
  margin-left: 8px;
}
.filter-chips .label.me-2,
.cdk-overlay-pane h5.ps-3.pt-3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.filter-chips button.mx-2 {
  font-size: 16px;
  border-radius: 0px;
  height: 40px;
  border: 2px solid #C7D326;
  background-color: transparent;
  color: #000;
}
.filter-chips button.mx-2:hover {
  border-color: #000;
}
.collapsed-chips {
  max-width: 85% !important;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #C7D326 !important;
}
.cdk-overlay-container .d-flex.justify-content-between.mb-3.mt-2.mx-1 {
  margin: 10px 20px !important;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.facets-component button.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base {
  font-size: 16px;
  border-radius: 0px;
  padding: 20px 20px;
  height: 45px;
  border: 2px solid #C7D326;
  background-color: transparent;
  color: #000;
}
.facets-component button.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base:hover {
  border-color: #000;
}
.facets-component button#expFilterBtn {
  color: #212529;
}
//Facet-filter
//job-recomd
.job-apply .section {
  padding: 0 !important;
}
lib-job-apply .job-apply .zm-margin-top-5 .row.mb-2 {
  margin: 20px 0% 0;
}
.jb-rec-jbview lib-job-recommendation .job-recommendation.container {
  width: 100%;
  margin-top: 35px;
}
.job-recommendation .mat-mdc-card-content {
  padding: 0px 11px;
}
.job-recommendation .mat-mdc-card-content .flex-nowrap .col.fw-bold {
  margin-left: -5px;
}
.job-recommendation .mat-mdc-card {
  height: 140px !important;
}
.job-recommendation button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base.ng-star-inserted {
  margin-top: 10px;
}
.job-recommendation .mdc-card__actions {
  position: absolute;
  right: unset;
  left: 0;
  top: unset;
  bottom: 0px;
  padding-left: 5px;
}
//job-recomd
// ITC Menu
.hidden-lg {
  display: none;
}
nav.navbar.navbar-expand-lg.hidden-lg {
  margin-top: 80px;
}
button.navbar-toggler {
  background: #37398f;
  border: 1px solid #eaeaea;
  border-radius: 0px;
  padding: 3px 6px;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
a.nav-link {
  padding: 10px 20px;
  color: #343434 !important;
  font-family: 'Raleway', sans-serif !important;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
}
.container-fluid {
  padding: 0 15px !important;
}
.scroll_down {
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  position: sticky;
  z-index: 99;
  height: 50px;
  top: 80px;
  background: #fff;
  margin-top: 80px;
}
.scroll_down ul {
  display: flex;
  justify-content: center;
}
.scroll_down ul {
  margin: 0 auto;
  text-align: center;
  padding: 0;
}
.scroll_down li {
  display: inline-block;
  cursor: pointer;
  float: left;
  width: 20%;
  background: #ffffff;
  font-size: 17px;
  text-align: center;
  margin: 0;
  vertical-align: top;
  line-height: 50px;
}
.scroll_down li:first-child {
  margin: 0;
}
.scroll_down li a {
  color: #383838 !important;
  text-decoration: none;
  font-size: 17px;
  font-weight: normal;
  outline: none;
}
li:after {
  content: " ";
  position: absolute;
  left: 45%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #ddd;
  transition: 0.1s ease-in-out;
}
.nav-active {
  background: #c7d326 !important;
}
// ITC Menu
//ITC
.job-apply span.colon {
  display: none;
}
.open-position .col-md-4.col-sm-12.col-xs-12:nth-of-type(1n+7) {
  display: none !important;
}
.testimonial .carousel-indicators,
.emp-testimonial .carousel-indicators {
  margin-bottom: 0rem !important;
}
.itc-domaim .carousel-indicators {
  margin-bottom: -2rem !important;
}
.job-view.container .section {
  padding: 0 !important;
}
button.exp-submit-mobile.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base {
  width: 100%;
  border: 2px solid #C7D326 !important;
  background: #fff;
  font-size: 18px;
  color: #000 !important;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 0px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  width: 99%;
}
button.exp-submit-mobile.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base:hover {
  border-color: #333333 !important;
}
.open-position .mat-mdc-card-title a {
  text-transform: uppercase;
  font-size: 18px !important;
  letter-spacing: 1px;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  margin-top: 15px;
}
.open-position .mat-mdc-card {
  margin-bottom: 15px;
  border-radius: 0px !important;
}
.open-position .mat-mdc-card-content {
  font-size: 14px !important;
  font-weight: normal;
  color: rgb(0, 0, 0);
}
.open-position .col.fw-bold {
  font-size: 14px !important;
  font-weight: normal !important;
  color: rgb(0, 0, 0);
}
.open-position .mat-mdc-card-content .row {
  padding-bottom: 0px;
}
.job-view-skillset {
  display: none;
}
.open-position mat-card-actions.mat-mdc-card-actions.mdc-card__actions {
  display: none;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.carousel-indicators button {
  width: 5px !important;
  height: 5px !important;
  border-radius: 100px !important;
  border: 5px solid #000 !important;
  background: #fff !important;
  opacity: .8 !important;
  margin-left: 0px !important;
}
.carousel-indicators .active {
  border: 5px solid #fff !important;
  background: #000 !important;
  opacity: .8 !important;
}
.attribute-label {
  line-height: 1 !important;
}
section.mtb-30.mt-80.jobConfirm-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
  font-size: 14px !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #333 !important;
}
.search-bar .action-btns-group {
  margin-bottom: 1rem !important;
}
.checkbox.checkbox-primary {
  margin-top: 0px;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  border-radius: 4px;
}
button.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base {
  font-size: 16px;
  border-radius: 0px;
  padding: 20px 20px;
  height: 45px;
  border: 2px solid #C7D326;
  background-color: transparent;
}
.job-list-sec button.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base {
  margin-top: 30px;
}
button.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base:hover {
  border-color: #000;
}
.mat-mdc-card {
  flex-direction: column;
  display: flex;
  margin-bottom: 0px;
  border-radius: 0px !important;
  background: #fff;
  border: 0;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%);
  transition: box-shadow .15s ease-in-out;
  background-color: #fff;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.mat-mdc-card-content .row {
  margin-left: -7px;
  margin-right: -7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 2px;
}
.header__left {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
}
.header__right .mat-mdc-outlined-button:not(:disabled){
  background-color: rgba(255, 0, 0, 0);
  color: #6c407f !important;
  text-decoration-line: underline;
  border: none;
  font-size: 13px;
  font-weight: 600;
}
.section-label label{
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 5px 0px;
  text-align: left;
  white-space: initial;
  line-height: 1.3;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.label {
  padding: 0;
}
button.zm-ml-1.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-unthemed.mat-mdc-button-base {
  background: transparent;
  color: #000 !important;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #C7D326 !important;
  padding: 13px 13px;
  letter-spacing: 0px;
}
button.zm-ml-1.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-unthemed.mat-mdc-button-base:hover {
  border-color: #333333 !important;
}
.zm-ml-1 {
  margin-left: 10px !important;
}
mat-card[_ngcontent-ghq-c272]:hover{
  box-shadow: none !important;
}
.mat-mdc-card-title a{
  color: #333!important;
  font-weight: 600;
  font-size: 18px;
}
.mat-mdc-card-content, .flex-nowrap .col.fw-bold
{
  font-size: 14px!important;
  color: #333;
}
@media (min-width: 992px) {
  .mdc-card__actions {
    position: unset;
    bottom: 0px;
    width: 89%;
}
.search-bar .search-bar-input {
  padding-left: 10px;
}
.col-md-4.col-sm-12.col-xs-12 {
  padding-right: 0px;
}
.job-apply .row.mb-2 {
  margin-bottom: 2rem !important;
}
.zm-margin-top-5 .row.mb-2 .col-md-3.attribute-label {
  width: 100% !important;
  display: block !important;
}
//job-recom
.col-md-4.jb-rec-jbview.pd-22rem.ng-star-inserted {
  padding: 15px;
  margin-top: 10px;
}
.pd-22rem {
  padding-top: 3rem !important;
}
.job-apply .col-md-9 {
  width: 100%;
}
.job-apply .col-md-7 {
  width: 75.333333%;
}
.col-md-2.file-btn {
  width: 24.666667%;
}
//job-recom
//Facet-filter
.cdk-overlay-pane {
  width: unset !important;
}
.filter-chips .label.me-2 {
  display: none;
}
//Facet-filter
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
.mat-mdc-card-actions.mdc-card__actions .mdc-button.mat-mdc-button.mat-primary.mat-mdc-button-base
{
  color: #C7D326 !important;
  font-weight: 400;
  font-size: 14px;
  float: left;
  cursor: pointer;
}
// job-view-page
.job-view.container .row .col-md-3.attribute-label, .job-view.container .row .col-md-9.attribute-data {
  width: 100% !important;
}
// job-view-page
.job-view__attribute label.attribute-text, .job-view label.attribute-text {
  color: #605e5c !important;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.3;
  padding-bottom: 10px;
}
.job-view__attribute span.colon {
  display: none;
}
p {
  color: #333;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}
.mat-mdc-text-field-wrapper {
  background-color: #eeeeee;
  border-radius: 5px !important;
  font-size: 16px;
}
.col-md-2.file-btn .mat-mdc-unelevated-button:not(:disabled) {
  border-radius: 0px 5px 5px 0px;
}
.mat-mdc-text-field-wrapper {
  width: 100%;
  border-radius: 5px 0px 0px 5px !important;
}
.footer.text-center .mat-mdc-raised-button:not(:disabled) {
  color: #333!important;
  height: 45px;
  padding: 6px 28px;
}
.job-view__title h2
{
  color: #333;
  font-size: 27px;
  padding: 30px 10px 30px 0px;
  margin: 0;
}
.job-apply.container label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: normal;
  color: #605E5C;
  font-size: 22px;
}
.section[_ngcontent-onn-c281] {
  padding: 2em 0px !important;
}
.joblist-sec .search-bar .action-btns-group .mat-mdc-unelevated-button {
  display: none;
}
.mat-mdc-checkbox label {
  cursor: pointer;
  font-weight: 400!important;
  font-size: 14px!important;
  color: #333;
}
.mat-mdc-form-field-hint-wrapper .mat-mdc-form-field-hint-spacer {
  flex: unset;
}
.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align .mat-mdc-form-field-hint-wrapper
{
  padding: 0 5px;
}
.label.section-label .mat-divider {
  display: none;
}
.section__xp__fields .mat-mdc-text-field-wrapper {
  background: #fff;
}
@media (max-width: 495px){
.width-auto.facet-lib .header, .width-auto.facet-lib .section{
  padding: 0px 10px !important;
}
}
@media (min-width: 320px) and (max-width: 500px){
  .search-bar {
      flex-direction: unset !important;
  }}
  .mat-mdc-checkbox label {
    text-transform: capitalize;
}
@media (max-width: 991px){
h2.abt-cntr {
    font-size: 32px!important;
    line-height: 45px;
}
.job-apply.container .zm-margin-top-5 .col-md-7.file-input{
  padding: 0!important;
}
.job-apply.container .zm-margin-top-5 .col-md-2.file-btn button{
  width: 100%!important;
  border-radius: 5px !important;
}
.job-apply.container .zm-margin-top-5 .col-md-9{
  padding-right: 0px;
  padding-left: 0px
}
.job-apply.container .zm-margin-top-5 .col-md-3.attribute-label{
  display: block;
  padding-right: 0px;
  padding-left: 0px;
}
.job-list-sec .mdc-card__actions{
  justify-content: left !important;
}
}
.job-apply.container .footer.text-center button{
  color: #fff !important;
  height: 43px;
  border-radius: 0px !important;
  font-weight: 600;
  font-size: 18px;
  padding: 6px 48px;
  border: none;
  margin-bottom: 40px;
  line-height: 1.3;
  letter-spacing: 0.5px;
}
.mat-mdc-checkbox label {
  cursor: pointer;
  padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2);
}
@media (max-width: 768px) {
  .search-bar .search-bar-input{
    width: 75% !important;
  }
  .search-bar .action-btns-group{
    width: 25% !important;
  }
  .mat-mdc-outlined-button:not(:disabled) {
    font-size: 14px;
  }
  .job-view__title h2 {
    font-size: 32px;
  }
  .hidden-sm {
    display: none !important;
  }
  .hidden-lg {
    display: block;
  }
  .mat-mdc-card {
    height: unset !important;
  }
  //Facet-filter
  .filter-chips.d-flex {
    margin-left: 20px;
    margin-right: 10px;
  }
  .filter-chips .label-mobile-view {
    max-width: 60% !important;
  }
  .filter-chips.d-flex {
    display: block !important;
  }
  mat-dialog-container .justify-content-end.footer {
    position: sticky;
    bottom: 0px;
    padding-top: 20px;
  }
  mat-dialog-container .justify-content-end.footer {
    position: fixed;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px !important;
    right: 0;
    padding-bottom: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border: 1px solid #E0E0E0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    opacity: 1;
    width: 100%;
    z-index: 99;
  }
  .d-none-mob {
    display: none;
  }
  //Facet-filter
}
.col-md-2.resume-upload--btn .mdc-fab {
  width: 50px;
  height: 50px;
  margin-left: 5px;

}
@media (max-width: 991px){
.zm-margin-top-5 .row.resume-upload .col-md-2.resume-upload--btn {
    float: left;
    width: 16%;
    margin-left: 5px;
}
.col-md-2.resume-upload--btn .mdc-fab {
  width: 45px;
  height: 45px;
}
.zm-margin-top-5 .row.resume-upload .col-md-10.resume-upload__ipt input#mat-input-8 {
  font-size: 12px;
}
}
.zm-margin-top-5 .row.resume-upload .col-md-10.resume-upload__ipt {
  padding-right: 0;
  float: left;
  width: 80%;
}
//ITC
